import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyBanner = lazy(
  () => import("./banner-BYMkO8Ad.js").then((module) => ({
    default: module.Banner
  }))
);
function Banner({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyBanner, { ...props });
}
const useBannerContent = () => {
  return useMemo(
    () => ({
      hero: {
        component: Banner
      }
    }),
    []
  );
};
export {
  LazyBanner as Banner,
  useBannerContent
};
